<template>
  <div class="py-14" style="background-color:#fff;min-height:100vh">
    <v-list class="py-0">
      <v-list-item-group>

        <v-list-item to="/master/store">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Toko</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <v-list-item to="/master/user">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Pegawai</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <v-list-item to="/master/sales-type">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Tipe Penjualan</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

        <v-list-item to="/master/category">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Kategori Produk</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>


        <v-list-item to="/master/product">
          <v-list-item-content>
            <v-list-item-title class="text-title" style="font-weight: 500">Produk</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="#D0D0D0">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-auto"></v-divider>

      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({

  }),
  watch: {

  },
  computed: {

  },
  created() {

  },
  mounted(){
    
  },
  methods: {

  },
}
</script>